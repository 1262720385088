import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export const createMap = () => {
  const map = L.map('map', {
    center: [46.2276, 2.2137], // center of France
    zoom: 6, // zoom level to show the whole country
    maxBounds: L.latLngBounds(L.latLng(41, -5), L.latLng(52, 10)), // restrict map panning outside of France
  });

  return map;
};

export const addTileLayer = (map) => {
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Map data &copy; OpenStreetMap contributors',
  }).addTo(map);
};
