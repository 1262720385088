import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import SidePanel from './SidePanel';
import { createMarkers } from './Marker';
import { createMap, addTileLayer } from './MapSetup';
import airfieldDatabase from './airfieldDB.json';
import { filterAirfieldsDatabase } from './filterUtils';

function Map() {
  const extractFuelProviders = (database) => {
    const providersSet = new Set();
    Object.values(database).forEach((airportInfo) => {
      if (airportInfo && airportInfo.AVT && airportInfo.AVT.provider) {
        airportInfo.AVT.provider.forEach((provider) => {
          providersSet.add(provider);
        });
      }
    });
    return Array.from(providersSet);
  };
  
  const [fields, setFields] = useState(() => {
    // Extract fuel providers from airfieldDB.json
    const providers = extractFuelProviders(airfieldDatabase);
  
    // Initialize the fields with the basic fields
    const basicFields = [
      { label: 'ATS', isChecked: false },
      { label: 'AVT', isChecked: false },
      { label: 'CUSTOMS', isChecked: false },
      { label: 'FOOD', isChecked: false },
      { label: 'RCH', isChecked: false },
      { label: 'VISITED', isChecked: false },
      { label: 'MISSING', isChecked: false },
    ];
  
    // Create fuel provider fields
    const fuelProviderFields = providers.map(provider => ({
      label: provider,
      isChecked: false,
    }));
  
    // Combine basic fields with fuel provider fields
    return [...basicFields, ...fuelProviderFields];
  });
  
  const [fuelProviders, setFuelProviders] = useState([]);
  const [isRetracted, setIsRetracted] = useState(true);

  useEffect(() => {
    // Extract fuel providers from airfieldDB.json
    const providers = extractFuelProviders(airfieldDatabase);
    console.log('Fuel Providers:', providers); // Log the extracted fuel providers
    setFuelProviders(providers);
  }, []);

  useEffect(() => {
    let map;

    if (!map) {
      // create map
      map = createMap();
      addTileLayer(map);
    }
    
    const filteredDB = filterAirfieldsDatabase(airfieldDatabase, fields);

    createMarkers(map, filteredDB);

    // Cleanup function to remove markers and destroy the map on unmount
    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });
      map.remove();
    };
  }, [fields]);

  const handleNormalFieldChange = (label, isChecked) => {
    let updatedFields = fields.map((f) =>
      f.label === label ? { ...f, isChecked } : f
    );
    setFields(updatedFields);
  };

  const handleSpecialFieldChange = (label, isChecked) => {
    let updatedFields = [...fields];
  
    updatedFields = updatedFields.map((f) =>
      f.label === label ? { ...f, isChecked } : f
    );
  
    if (isChecked) {
      // When a fuel provider is checked, AVT should also be checked
      if (fuelProviders.includes(label)) {
        updatedFields = updatedFields.map((f) =>
          f.label === 'AVT' ? { ...f, isChecked: true } : f
        );
      }
    } else {
      // When a fuel provider is unchecked, AVT should be unchecked if no other fuel provider is checked
      const anyProviderChecked = updatedFields.some((f) =>
        fuelProviders.includes(f.label) && f.isChecked
      );
      if (!anyProviderChecked) {
        updatedFields = updatedFields.map((f) =>
          f.label === 'AVT' ? { ...f, isChecked: false } : f
        );
      }
    }
  
    setFields(updatedFields);
  };
  

  const handleFieldChange = (label, isChecked) => {
    if (fuelProviders.includes(label)) {
      handleSpecialFieldChange(label, isChecked);
    } else {
      handleNormalFieldChange(label, isChecked);
    }
  };

  const handleToggleRetraction = () => {
    setIsRetracted((prevIsRetracted) => !prevIsRetracted);
  };

  return (
    <div className="map-container">
      <SidePanel
        fields={fields}
        fuelProviders={fuelProviders}
        isRetracted={isRetracted}
        onToggleRetraction={handleToggleRetraction}
        onFieldChange={handleFieldChange} // Pass the onFieldChange function here
      />
      <div id="map"></div>
    </div>
  );
}

export default Map;
