import React from 'react';
import './SidePanel.css'; // Import CSS file for styling
import metaData from './vacd_metaData.json'; // Import metaData

const SidePanel = ({ fields, fuelProviders, onFieldChange, isRetracted, onToggleRetraction }) => {
  const { AIP } = metaData;
  const versionDate = AIP.substring(5); // Remove the prefix "eAIP_"

  // Filter out AVT field and dynamic fields from the standard fields
  const standardFields = fields.filter(field => field.label !== 'AVT' && !fuelProviders.includes(field.label));

  // Find the AVT field
  const avtField = fields.find(field => field.label === 'AVT');

  // Render dynamic fields with indentation
  const dynamicFields = fuelProviders.map(provider => (
    <div key={provider} className="indented">
      <input
        type="checkbox"
        id={provider}
        checked={fields.some((field) => field.label === provider && field.isChecked)}
        onChange={(e) => onFieldChange(provider, e.target.checked)}
      />
      <label htmlFor={provider}>{provider}</label>
    </div>
  ));

  return (
    <div className={`side-panel ${isRetracted ? 'retracted' : ''}`}>
      <div className="toggle-button" onClick={onToggleRetraction}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24">
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </div>
      {!isRetracted && (
        <>
          <div className="panel-header">
            <h2 className="panel-title">Filtres</h2>
          </div>
          <div className="checkboxes">
            {/* Render the three standard checkboxes */}
            {standardFields.map((field) => (
              <div key={field.label}>
                <input
                  type="checkbox"
                  id={field.label}
                  checked={field.isChecked}
                  onChange={(e) => onFieldChange(field.label, e.target.checked)}
                />
                <label htmlFor={field.label}>{field.label}</label>
              </div>
            ))}
          </div>
          {/* Render the AVT checkbox */}
          <div className="checkboxes">
            <div key={avtField.label}>
              <input
                type="checkbox"
                id={avtField.label}
                checked={avtField.isChecked}
                onChange={(e) => onFieldChange(avtField.label, e.target.checked)}
              />
              <label htmlFor={avtField.label}>{avtField.label}</label>
            </div>
          </div>
          {/* Render dynamic fields */}
          <div className="dynamic-checkboxes">
            {dynamicFields}
          </div>
        </>
      )}
      {/* Render AIP value */}
      {!isRetracted && (
        <div className="aip-info">
        <p>AIP: {versionDate}</p>
      </div>
      )}
    </div>
  );
};

export default SidePanel;
