import React, { useEffect } from 'react';
import Map from './Map';

function App() {
  useEffect(() => {
    document.title = 'vacd-demo'; // Set your custom title here
  }, []);

  return (
    <div>
      <Map />
    </div>
  );
}

export default App;
