export const filterStandardFields = (airportInfo, fieldLabel) => {
    if (airportInfo && airportInfo[fieldLabel]) {
      return airportInfo[fieldLabel].some((info) => info !== 'NIL.');
    }
    return false;
  };
  
  export const filterAVTFields = (icao, airportInfo, fields) => {
    if (!fields.some(field => field.label === 'AVT')) {
      console.log('AVT field not checked, including airport:', icao);
      return true;
    }
  
    if (airportInfo && airportInfo.AVT && airportInfo.AVT.availability === 'yes') {
      return true;
    }
  
    return false;
  };

  export const filterDynamicFields = (icao, airportInfo, fields) => {
    // Extract all checked dynamic fields (AVT providers)
    const checkedDynamicFields = fields.filter((field) => field.isChecked && field.label !== 'AVT').map((field) => field.label);
  
    if (checkedDynamicFields.length === 0) {
      // If no dynamic fields are checked, include the airport
      return true;
    }
  
    // Check if any AVT provider matches the checked dynamic fields
    if (airportInfo && airportInfo.AVT && airportInfo.AVT.provider) {
      return airportInfo.AVT.provider.some((provider) => checkedDynamicFields.includes(provider));
    }
  
    return false;
  };
  
  export const filterAirfieldsDatabase = (airfieldDatabase, fields) => {
    const filtered = {};
    const standardFields = ['ATS', 'CUSTOMS', 'FOOD', 'RCH', 'VISITED', 'MISSING']

    Object.entries(airfieldDatabase).forEach(([icao, airportInfo]) => {
      if (
        fields.every((field) => {
          if (!field.isChecked) {
            return true; // Consider unchecked fields as a match
          }
          if (field.label === 'AVT') {
            return filterAVTFields(icao, airportInfo, fields); // Call filterAVTFields for AVT
          }
          if (standardFields.includes(field.label)) {
            return filterStandardFields(airportInfo, field.label);
          }
          if (field.label !== 'AVT' && !standardFields.includes(field.label)) {
            return filterDynamicFields(icao, airportInfo, fields);
          }
          return false;
        })
      ) {
        filtered[icao] = airportInfo;
      }
    });
  
    return filtered;
  };